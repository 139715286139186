import {Controller} from "stimulus";
import {createPopper, Instance, Modifier, Placement} from "@popperjs/core";

const ARROW_SIZE = 5;

type TooltipPosition = "above" | "below" | "left" | "right";

const POSITION_TO_PLACEMENT: Record<TooltipPosition, Placement> = {
  above: "top",
  below: "bottom",
  left: "left",
  right: "right",
};

const FLIPPED_PLACEMENTS: Partial<Record<Placement, Placement>> = {
  bottom: "top",
  left: "right",
  right: "left",
  top: "bottom",
};

const titleCase = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

// Arrow placement is the opposite of popper placement (top ~ bottom)
// "top" and "left" are overriden by popperjs (but wen use "marginTop" and "marginLeft")
const positionArrow = (arrow: HTMLElement, currentPlacement: Placement) => {
  // Reset previous (unflipped) placement if set
  arrow.style[currentPlacement] = null;
  arrow.style[`margin${titleCase(currentPlacement)}`] = null;

  // Set new placement
  const arrowPlacement = FLIPPED_PLACEMENTS[currentPlacement] as Placement;
  if (arrowPlacement === "top" || arrowPlacement == "left") {
    arrow.style[`margin${titleCase(arrowPlacement)}`] = `-${ARROW_SIZE}px`;
  } else {
    arrow.style[arrowPlacement] = `-${ARROW_SIZE}px`;
  }
};

// Handles the orientation of the arrow (rotates 180 if flipped)
const orientArrow = (arrowImage: SVGElement, isFlipped: boolean) => {
  const flippedClasses = ["relative", "transform", "rotate-180"];
  if (isFlipped) {
    arrowImage.classList.add(...flippedClasses);
  } else {
    arrowImage.classList.remove(...flippedClasses);
  }
};

const customArrowModifier = {
  name: "customArrow",
  requires: ["arrow"],
  enabled: true,
  phase: "main",
  // The "effect" is run once at the start of the lifecycle
  // Clean away any of our normal arrow styles (popperjs will be controlling these instead)
  effect: ({state}) => {
    const arrow = state.elements.arrow;
    if (arrow) {
      arrow.classList.remove(...Array.from(arrow.classList));
      arrow.classList.add("flex");
    }
  },
  // Primary lifecycle update function
  fn({state}) {
    const {
      elements: {arrow},
      placement: currentPlacement,
      options: {placement: originalPlacement},
    } = state;

    if (arrow) {
      positionArrow(arrow, state.placement);
      orientArrow(arrow.querySelector("svg")!, currentPlacement !== originalPlacement);
    }
  },
} as Modifier<"customArrow", undefined>;

const popperOptions = (placement: Placement, arrow: HTMLElement) => ({
  placement: placement,
  modifiers: [
    {
      name: "arrow",
      options: {
        element: arrow,
        padding: ARROW_SIZE,
      },
    },
    {
      name: "offset",
      options: {
        offset: [0, ARROW_SIZE],
      },
    },
    {
      ...customArrowModifier,
    },
  ],
});

export default class WithTooltipController extends Controller {
  static targets = ["tooltip", "positioner"];
  declare tooltipTarget: HTMLElement;
  declare positionerTarget: HTMLElement;
  declare visible: string[];
  declare hidden: string[];

  private popper: Instance;

  connect() {
    this.visible = this.data.get("stylesVisible")!.split(" ");
    this.hidden = this.data.get("stylesHidden")!.split(" ");

    this.element.addEventListener("tlw-draggable:start", this.hide);
    this.element.addEventListener("tlw-draggable:end", this.hide);
    this.element.addEventListener("tlw-draggable:cancel", this.hide);
    this.element.addEventListener("tlw-resizable:start", this.hide);
    this.element.addEventListener("tlw-resizable:end", this.hide);
    this.element.addEventListener("tlw-resizable:cancel", this.hide);
  }

  disconnect() {
    this.removeListeners();
    this.element.removeEventListener("tlw-draggable:start", this.hide);
    this.element.removeEventListener("tlw-draggable:end", this.hide);
    this.element.removeEventListener("tlw-draggable:cancel", this.hide);
    this.element.removeEventListener("tlw-resizable:start", this.hide);
    this.element.removeEventListener("tlw-resizable:end", this.hide);
    this.element.removeEventListener("tlw-resizable:cancel", this.hide);
  }

  show = () => {
    if (this.data.get("disabled") === "true") return;

    this.removeListeners();
    this.showPopper();
  };

  hide = (event?: any) => {
    if (this.element.contains(event?.relatedTarget)) return;

    this.removeListeners();
    this.tooltipTarget.addEventListener("transitionend", this.hidePopper);
    this.animateOut();
  };

  disable = () => {
    this.data.set("disabled", "true");
    this.hide();
  };

  enable = () => {
    this.data.set("disabled", "false");
  };

  private showPopper = () => {
    // Initialise popper on first show
    if (!this.popper) {
      this.popper = createPopper(
        this.element,
        this.positionerTarget,
        popperOptions(
          POSITION_TO_PLACEMENT[this.data.get("position")!],
          this.positionerTarget.querySelector("[role=presentation]")!
        )
      );
    }

    this.popper.update();
    this.positionerTarget.classList.remove("hidden");

    // Fix rendering bug
    setTimeout(() => this.popper.forceUpdate(), 1);
    setTimeout(this.animateIn); // Execute on next "tick"
  };

  private hidePopper = () => {
    this.positionerTarget.classList.add("hidden");
  };

  private animateIn = () => {
    this.tooltipTarget.classList.remove(...this.hidden);
    this.tooltipTarget.classList.add(...this.visible);
  };

  private animateOut = () => {
    this.tooltipTarget.classList.remove(...this.visible);
    this.tooltipTarget.classList.add(...this.hidden);
  };

  private removeListeners = () =>
    this.tooltipTarget.removeEventListener("transitionend", this.hidePopper);
}
